@import '~www/themes/doctors/common/variables';

.b-card-nav {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  background-color: $ui-kit-bg-gray-0;
  box-shadow: 0 15px 20px rgba(151, 158, 167, 0.1);
  transition: 0.2s ease-in-out;
  will-change: transform;

  &:hover {
    box-shadow: 0 15px 20px rgba(151, 158, 167, 0.2);
    transform: translateY(-10px);
  }

  &__header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $weight-medium;
    line-height: $line-height-base;
    color: $color-neur-dark;
    text-decoration: none;
  }

  &__header:hover &__title {
    text-decoration: underline;
  }

  &__main {
    flex: 1 1 auto;
    padding: 0 24px;
  }

  &__footer {
    padding: 0 24px 24px;
  }

  &__all-count {
    font-size: $font-size-lg;
    font-weight: $weight-medium;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;
    text-decoration: none;
  }

  &__list {
    margin: 0;
    padding: 16px 0;
    border-top: 1px solid $ui-kit-bg-gray-60;
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
  }

  &__item-link {
    flex: 1;
    display: block;
    padding: 8px 16px 8px 0;
    font-size: $font-size-body-1;
    font-weight: $weight-medium;
    line-height: $line-height-base;
    color: $color-dark-grey;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: $ui-kit-primary;
      text-decoration: underline;
    }
  }

  &__item-count {
    padding: 8px 0 8px 16px;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;
  }
}
