/**
 * @author Кирилл Шабанов (@shabanov)
 * @copyright Быков Александр (@bykov)
 */

$directions: (
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
  'x': ('left', 'right'),
  'y': ('top', 'bottom'),
  '': ('top', 'right', 'bottom', 'left'),
);
$props: (
  'm': 'margin',
  'p': 'padding',
);

@each $prop-key, $prop-value in $props {
  @each $direction-key, $direction-value in $directions {
    // Положительные отступы для padding и margin
    @for $i from 0 through 20 {
      .#{$prop-key}#{$direction-key}-#{$i} {
        @each $direction in $direction-value {
          #{$prop-value}-#{$direction}: $i * 4px !important;
        }
      }
    }

    // Отрицательные отступы только для margin
    @if $prop-value == 'margin' {
      /**
            ** Цикл начинается с 1 затем, чтобы не было бессмысленных классов типа `mx-n0`,
            ** который является полным аналогом `mx-0` из цикла выше
            */
      @for $i from 1 through 20 {
        .#{$prop-key}#{$direction-key}-n#{$i} {
          @each $direction in $direction-value {
            #{$prop-value}-#{$direction}: $i * -4px !important;
          }
        }
      }
    }

    // Автоматические отступы для margin
    @if $prop-value == 'margin' {
      .#{$prop-key}#{$direction-key}-auto {
        @each $direction in $direction-value {
          #{$prop-value}-#{$direction}: auto !important;
        }
      }
    }
  }
}
