@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/atom/index';
@import '~www/themes/doctors/common/icon-font';

@font-face {
  font-display: swap;
  font-family: Inter;
  src: url('/static/_v1/pd/fonts/Inter/Inter-Regular.eot');
  src: url('/static/_v1/pd/fonts/Inter/Inter-Regular.woff2') format('woff2'),
    url('/static/_v1/pd/fonts/Inter/Inter-Regular.woff') format('woff'),
    url('/static/_v1/pd/fonts/Inter/Inter-Regular.ttf') format('truetype'),
    url('/static/_v1/pd/fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: $weight-regular;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: Inter;
  src: url('/static/_v1/pd/fonts/Inter/Inter-Medium.eot');
  src: url('/static/_v1/pd/fonts/Inter/Inter-Medium.woff2') format('woff2'),
    url('/static/_v1/pd/fonts/Inter/Inter-Medium.woff') format('woff'),
    url('/static/_v1/pd/fonts/Inter/Inter-Medium.ttf') format('truetype'),
    url('/static/_v1/pd/fonts/Inter/Inter-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: $weight-medium;
  font-style: normal;
}

html {
  // Перезаписывает стили от ress.css (сброс стилей от Vuetify)
  // TODO: Удалить при обновлении Vuetify или ress.css до ^3.0.0 (когда будет отсутствовать модицикация overflow у html)
  overflow: initial;
  overflow-x: hidden !important;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: $font-family-regular;
  font-size: 14px;
  background-color: $ui-kit-bg-gray-0;
}

a {
  color: $ui-kit-text;
}

// Фикс отображения для IE11
// TODO: Удалить при отказе от поддержки IE11
main,
aside,
article,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
th {
  font-weight: $weight-medium;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}
