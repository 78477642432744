// width

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

// height

.h-100 {
  height: 100% !important;
}
