@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

.b-stage-alert {
  z-index: 9999999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 8px 16px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
  font-weight: $weight-medium;
  font-style: italic;
  background-color: $ui-kit-secondary;
  color: $ui-kit-bg-gray-0;
  opacity: 0.7;
  pointer-events: none;
}

@include media-breakpoint-down(sm) {
  .b-stage-alert {
    top: 0;
    bottom: initial;
    font-size: 0;
    padding: 4px;
  }
}
