/* виды оверлеев (светлый, черный) */
.mag-popup_bg-type_light.mfp-bg {
  background: #FAFCFD;
  opacity: 0.67;
}

.mag-popup.mfp-bg {
  background: #091117;
  opacity: 0.67;
}

/* start state */
.mag-popup_anim_vertical .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s;
  transform: translateY(50px);
}

.mag-popup_anim_vertical.mfp-bg {
  opacity: 0;
  transition: all 0.2s;
}

/* animate in */
.mag-popup_anim_vertical.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateY(0);
}

.mag-popup_anim_vertical.mfp-ready.mfp-bg {
  opacity: 0.67;
}

/* animate out */
.mag-popup_anim_vertical.mfp-removing .mfp-with-anim {
  transform: translateY(50px);
  opacity: 0;
}

.mag-popup_anim_vertical.mfp-removing.mfp-bg {
  opacity: 0;
}
