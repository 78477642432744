@import '~www/themes/doctors/common/variables';

.b-icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &_fill_none {
    fill: none !important;
  }

  &_valign_sub {
    vertical-align: sub;
  }

  &_valign_middle {
    vertical-align: middle;
  }

  &_color_brand {
    color: $ui-kit-primary;
  }

  &_color_deepgrey {
    color: $ui-kit-text-secondary;
  }

  &_color_black {
    color: $ui-kit-icon-primary;
  }

  &_color_darkgrey {
    color: $ui-kit-icon-secondary;
  }

  &_color_danger {
    color: $color-original-red;
  }

  &_color_warn {
    color: $ui-kit-warning;
  }

  &_size_desktop {
    width: 20px;
    height: 20px;
  }

  &_size_mobile {
    width: 16px;
    height: 16px;
  }

  &_ui-button {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
  }
}
