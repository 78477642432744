@import '~www/themes/doctors/common/variables';

.b-popup {
  background: $ui-kit-bg-gray-0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.0494);
  border-radius: 5px;
  width: 710px;
  height: auto;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  font-family: $font-family-regular;

  &__title {
    font-style: normal;
    font-weight: $weight-medium;
    font-size: 20px;
    line-height: 23px;
    color: #515459;
    display: block;
    background: linear-gradient(180deg, #FFFFFF 0%, #FEFDFD 19.72%, #FDFCFC 34.2%, #F2F1F1 77.26%);
    padding: 20px 40px;
    border-radius: 5px 5px 0 0;
  }

  &__inner {
    padding: 40px;
  }

  &__body {
    padding: 0 24px 24px;
  }

  &__close-btn {
    font-size: 20px;
    line-height: 1em;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #000000;
    cursor: pointer;
  }

  /* кнопка закрытия в случае если это magnific b-popup */
  button.mfp-close {
    top: 0;
    right: 0;
    padding: 22px;
    line-height: 19px;
    color: #ADB0B8;
    opacity: 1;
    width: auto;
    height: auto;
    transition: all 0.15s;

    &:hover {
      background: #F1F3F6;
      color: #5B5D63;
    }
  }
}

.b-popup.b-popup_equipment-attribute {
  font-size: $font-size-body-1;

  .b-popup__title {
    padding-left: 24px;
  }

  .b-popup__image-wrap {
    margin: 0 -24px 24px;
  }

  .b-popup__image {
    width: 100%;
    object-fit: contain;
    height: 508px;
  }

  .b-popup__caption {
    margin-bottom: 8px;
  }

  .b-popup__description {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
