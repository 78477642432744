@import '~www/themes/doctors/common/variables';

@mixin background-transition-timing {
  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin reactions($hoverBack, $pressedBack, $fontColor) {
  @include background-transition-timing;

  &:hover {
    background-color: $hoverBack;
    color: $fontColor;
  }

  &:active {
    background-color: $pressedBack;
    color: $fontColor;
  }
}

.b-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  background-color: $ui-kit-bg-gray-60;
  color: $ui-kit-text;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  appearance: none;

  &_red {
    background-color: $ui-kit-secondary;
    color: $ui-kit-bg-gray-0;

    @include reactions(#CE2326, #AB2325, $ui-kit-bg-gray-0);
  }

  &_coral {
    background-color: $ui-kit-error;
    color: $ui-kit-bg-gray-0;

    @include reactions(#FF7070, #FF8989, $ui-kit-bg-gray-0);
  }

  &_outline#{&}_red {
    color: $ui-kit-secondary;
    border: 1px solid $ui-kit-secondary;

    @include reactions(#FEF3F4, $ui-kit-bg-error, $ui-kit-secondary);
  }

  &_text#{&}_red {
    color: $ui-kit-secondary;

    @include reactions(#FEF3F4, $ui-kit-bg-error, $ui-kit-secondary);
  }

  &_blue {
    background-color: $ui-kit-primary;
    color: $ui-kit-bg-gray-0;

    @include reactions(#136FA9, #165F8E, $ui-kit-bg-gray-0);
  }

  &_outline#{&}_blue {
    border: 1px solid $ui-kit-primary;
    color: $ui-kit-primary;

    @include reactions(#F6F9FF, #ECF1FB, $ui-kit-primary);
  }

  &_text#{&}_blue {
    color: $ui-kit-primary;

    @include reactions(#F6F9FF, #ECF1FB, $ui-kit-primary);
  }

  &_light {
    background-color: $ui-kit-bg-primary;
    color: $ui-kit-primary;

    @include reactions(#D1E8F4, #C1E0F0, $ui-kit-primary);
  }

  &_gray {
    background-color: $ui-kit-bg-gray-60;
    color: $ui-kit-text-secondary;

    @include reactions(#D9D9D9, $ui-kit-bg-gray-80, $ui-kit-primary);
  }

  &_outline#{&}_gray {
    border: 1px solid $ui-kit-text-info;

    @include reactions($ui-kit-bg-gray-40, $ui-kit-bg-gray-60, $ui-kit-primary);
  }

  &_text#{&}_gray {
    @include reactions($ui-kit-bg-gray-40, $ui-kit-bg-gray-60, $ui-kit-primary);
  }

  &:disabled,
  &_disabled {
    background-color: #F3F6FA;
    color: $ui-kit-text-info;
    cursor: initial;
    opacity: 1; // TODO: удалить при удалении jQuery Mobile, это фикс-костыль
  }

  &_outline#{&}:disabled, &_outline#{&}_disabled {
    background-color: transparent;
    border: 1px solid $ui-kit-bg-gray-80;
    color: $ui-kit-text-info;
  }

  &_text#{&}:disabled, &_outline#{&}_disabled {
    background-color: transparent;
    color: $ui-kit-text-info;
  }

  &_small {
    height: 28px;
    padding: 0 12px;
  }

  &_big {
    height: 56px;
  }

  &_full-width {
    width: 100%;
  }

  &_outline {
    background-color: transparent;
    border: 1px solid $ui-kit-text-info;
  }

  &_text {
    background-color: transparent;
    color: $ui-kit-text-secondary;
    border: none;
  }

  &_appendable {
    border-radius: 0 4px 4px 0;
  }
}

.b-button_coral-linear-gradient {
  background-image: $club-coral-linear-gradient;
}

.b-button_coral-radial-gradient {
  background-image: $ui-kit-club-gradient;
}

.b-button_coral-linear-gradient,
.b-button_coral-radial-gradient {
  .b-button__text {
    color: $ui-kit-bg-gray-0;
  }

  &.b-button_outline {
    border-width: 0;
    position: relative;
    z-index: 1;

    &::before {
      position: absolute;
      z-index: -1;
      inset: 1px;
      border-radius: $border-radius-sm;
      background-color: $ui-kit-bg-gray-0;
      content: '';
    }

    .b-button__text {
      background-image: $club-coral-linear-gradient;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
