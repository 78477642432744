@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

.b-pd-nav {
  padding-bottom: 40px;
  font-family: $font-family-regular;

  &__header {
    text-align: center;
    margin-bottom: 24px;
  }

  &__title {
    margin: 0;
    display: inline-flex;
    align-items: center;
    font-size: $font-size-xl;
    font-weight: $weight-regular;

    &::before {
      content: '';
      flex: 0 0 auto;
      display: inline-flex;
      width: 43px;
      height: 49px;
      background: url('/static/_v1/pd/icons/fire-red-with-shadow.svg') left center / contain no-repeat;
    }
  }

  &__title-text {
    color: $ui-kit-text-secondary;

    &::before {
      content: '';
    }
  }

  &__title-town-link {
    border-bottom: 2px dashed $color-neur-dark;
    font-weight: $weight-medium;
    color: $color-neur-dark;
    text-decoration: none;

    &:hover {
      color: $color-neur-dark;
    }
  }

  &__heading {
    display: inline-block;
    font-size: $font-size-body-2;
    font-weight: $weight-regular;
    min-height: 36px;
  }

  &__heading-text {
    background-image: url('/static/_v1/pd/icons/fire-red.svg');
    background-position-x: 3px;
    background-repeat: no-repeat;
    color: $ui-kit-text-secondary;
    display: block;
    margin-bottom: 6px;
    padding: 1px 0 0 22px;
    text-transform: uppercase;
    transform: translateX(-6px);
  }

  &__heading-town-link {
    border-bottom: 2px dashed $color-neur-dark;
    color: $color-neur-dark;
    font-size: $font-size-h6;
    font-weight: $weight-medium;
    text-decoration: none;

    &:hover {
      color: $color-neur-dark;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px -32px;
  }

  &__cards-item {
    padding: 0 16px;
    margin-bottom: 32px;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 25%;

    @include media-breakpoint-down(lg) {
      width: 50%;
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }
}
