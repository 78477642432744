@import '~www/themes/doctors/common/variables';

.b-town-search {
  width: 100%;

  .twitter-typeahead {
    width: 100%;
  }

  &__input-wrapp {
    width: 100%;
    position: relative;
  }

  &__input { // !important - временное решение, впоследствии их надо будет удалить
    font-family: $font-family-regular;
    width: 100% !important;
    font-size: 20px;
    transition: 0.2s ease-in-out;
    padding: 43px 40px !important;
    padding-right: calc(2.4em + 20px) !important;
    padding-bottom: 16px !important;
    background-image: url('/static/_v1/pd/icons/search/search-gray.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 40px) center;
    box-sizing: border-box;
    border: 1px solid #ECECEC !important;
    border-radius: 0 !important;
    height: auto !important;
    outline: none;

    &:focus {
      background-image: url('/static/_v1/pd/icons/search/search-black.svg');
    }

    %input-placeholder {
      color: $color-deep-soft-blue;
      font-size: 20px !important;
      font-family: $font-family-regular;
    }

    &::placeholder {
      @extend %input-placeholder;
    }
  }

  &__label {
    color: $color-deep-soft-blue;
    font-size: 20px;
    font-family: $font-family-regular;
    position: absolute;
    left: 40px;
    pointer-events: none;
    top: 50%;
    transition: 0.2s ease-in-out;
    transform: translateY(-50%);
    font-weight: $weight-regular;

    &_input_active {
      color: $color-deep-soft-blue;
      transform: translateY(calc(-50% - 17px));
      font-size: $font-size-body-1;
    }
  }

  &__highlight {
    font-weight: 500;
  }
}

.b-town-search__menu {
  background: $ui-kit-bg-gray-0;
  box-shadow: 0 25px 40px rgba(27, 27, 27, 0.15);
  width: 100%;
}

.b-town-search__suggestion {
  font-weight: $weight-regular;
  position: relative;
}

.b-town-search__suggestion::after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  left: 15px;
  right: 15px;
  bottom: 0;
  background: #E8E8E8;
}

.b-town-search__suggestion:last-child::after {
  display: none;
}

.b-town-search__suggestion-link {
  text-decoration: none;
  display: block;
  padding: 24px 40px;
  font-size: 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.b-town-search__suggestion-link:hover {
  color: $ui-kit-primary;
}

.b-town-search__empty {
  padding: 1em;
  font-size: 20px;
  color: #555555;
}
