// justify-content

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

// align-items

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

// flex-shrink

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

// flex-grow

.flex-grow-0 {
  flex-grow: 0 !important;
}

// flex-direction

.flex-direction-column {
  flex-direction: column !important;
}

// flex order (@see https://vuetifyjs.com/en/styles/flex/#flex-wrap)

.flex-wrap {
  flex-wrap: wrap !important;
}

// flex order (@see https://vuetifyjs.com/en/styles/flex/#flex-order)

.order-last {
  order: 13 !important;
}
