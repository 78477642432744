@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/functions';

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
// Inspired by https://getbootstrap.com/docs/5.0/layout/breakpoints/
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
// Inspired by https://getbootstrap.com/docs/5.0/layout/breakpoints/
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin linear-gradient($direction, $fromColor, $toColor) {
  background-color: $toColor; /* Fallback Color */
  background-image: linear-gradient($direction, $fromColor, $toColor);
}

// градиент с эффектом тумана, необходим для того, чтобы прятать длинный текст
/* stylelint-disable-next-line  scss/at-mixin-pattern */
@mixin hideGradientColor($R, $G, $B) {
  background: rgb($R, $G, $B);
  background: linear-gradient(
    to bottom,
    rgba($R, $G, $B, 0) 0%,
    rgba($R, $G, $B, 0.16) 16%,
    rgba($R, $G, $B, 0.2) 20%,
    rgba($R, $G, $B, 0.71) 71%,
    rgba($R, $G, $B, 1) 100%
  );
}

@mixin v-app-reset {
  background-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;

  .v-application--wrap {
    min-height: initial;
  }

  input {
    height: auto;
    border: none;
    border-radius: 0;
    padding: initial;
  }

  .v-input--selection-controls__input .v-icon {
    height: initial;
    background: transparent;
  }
}

// стили необходимые для использования иконочного шрифта
@mixin icomoon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin banner-info-background {
  background-image: url('/static/_v1/pd/icons/info-circle-corner.svg');
  background-position: bottom right;
}

@mixin custom-scroll {
  border-color: $ui-kit-bg-gray-60;
  transition: border-color .1s ease-in;

  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 4px;
    border-color: inherit;
    border-right-style: inset;
    border-right-width: calc(100vw + 100vh);
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: $ui-kit-bg-gray-40;
  }
}
