@import '~www/themes/doctors/common/variables';

// Цвета UI Kit
// Текст, основные
.ui-kit-color-text {
  color: $ui-kit-text !important;
}

.ui-kit-color-text-secondary {
  color: $ui-kit-text-secondary !important;
}

.ui-kit-color-text-info {
  color: $ui-kit-text-info !important;
}


// Текст, дополнительные
.ui-kit-color-text-success {
  color: $ui-kit-text-success !important;
}

.ui-kit-color-text-error {
  color: $ui-kit-text-error !important;
}

.ui-kit-color-text-warning {
  color: $ui-kit-text-warning !important;
}

.ui-kit-color-disabled {
  color: $ui-kit-disabled !important;
}


// Иконки
.ui-kit-color-icon-primary {
  color: $ui-kit-icon-primary !important;
}

.ui-kit-color-icon-secondary {
  color: $ui-kit-icon-secondary !important;
}


// Цвета бренда
.ui-kit-color-brand {
  color: $ui-kit-brand !important;
}

.ui-kit-color-secondary {
  color: $ui-kit-secondary !important;
}

.ui-kit-color-special {
  color: $ui-kit-special !important;
}


// Цвета состояний + вспомогательные
.ui-kit-color-primary {
  color: $ui-kit-primary !important;
}

.ui-kit-color-success {
  color: $ui-kit-success !important;
}

.ui-kit-color-error {
  color: $ui-kit-error !important;
}

.ui-kit-color-warning {
  color: $ui-kit-warning !important;
}


// Фоны + вспомогательные цвета
.ui-kit-color-bg-primary {
  color: $ui-kit-bg-primary !important;
}

.ui-kit-color-bg-secondary {
  color: $ui-kit-bg-secondary !important;
}

.ui-kit-color-bg-special {
  color: $ui-kit-bg-special !important;
}

.ui-kit-color-bg-success {
  color: $ui-kit-bg-success !important;
}

.ui-kit-color-bg-error {
  color: $ui-kit-bg-error !important;
}

.ui-kit-color-bg-warning {
  color: $ui-kit-bg-warning !important;
}

.ui-kit-color-bg-club {
  color: $ui-kit-bg-club !important;
}


.ui-kit-color-button-text-snackbars {
  color: $ui-kit-button-text-snackbars !important;
}

.ui-kit-color-rating {
  color: $ui-kit-rating !important;
}


.ui-kit-color-bg-gray-0 {
  color: $ui-kit-bg-gray-0 !important;
}

.ui-kit-color-bg-gray-40 {
  color: $ui-kit-bg-gray-40 !important;
}

.ui-kit-color-bg-gray-60 {
  color: $ui-kit-bg-gray-60 !important;
}

.ui-kit-color-bg-gray-80 {
  color: $ui-kit-bg-gray-80 !important;
}
