.button-reset {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  background: none;
  border: none;
  line-height: initial;
  font-size: initial;
  font-family: inherit;
  padding: 0;
  text-align: left;
  border-radius: initial;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &_hover {
    &:hover {
      background-color: transparent;
    }
  }
}
