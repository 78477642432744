@import '~www/themes/doctors/common/variables';

.footer {
  margin: 10px 0;
  padding: 10px 0 5px;
}

td.footer > div {
  background-color: #FFFFFF;
  color: #000000;
  font-family: $font-family-regular;
  font-size: 14px;
  margin: 0;
  overflow-x: hidden;
}

.footer__block {
  clear: both;
  padding: 0;
  width: 1140px;
  margin: 0 auto;
}

.footer__block_position {
  position: relative;
}

.footer__content {
  height: 234px;
  margin: 10px auto 0;
  padding-top: 10px;
  position: relative;
  width: 1000px;
}

.footer__metr {
  float: right;
  z-index: 5;
}

.footer__metr a {
  border: 0;
}

.footer__metr img:hover {
  cursor: pointer;
}

.footer__sidebar {
  width: 88px;
  display: inline-block;
  right: 0;
  top: 0;
  position: absolute;
}

.footer__links {
  width: calc(100% - 40px);
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.footer__link {
  display: inline-block;
  line-height: 16px;
  font-size: 15px;
  text-decoration: none;
  margin-top: 4px;
}

.footer__link:hover,
.footer__link:focus {
  color: #FF4A37;
}

.footer__link-btn {
  display: inline-block;
  line-height: 16px;
  font-size: 15px;
  text-decoration: none;
  margin-top: 4px;
  border: 0;
  padding: 0;
  height: 17px;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
}

.footer__link-btn:hover {
  color: red;
  background: transparent;
}

.footer__warning {
  box-sizing: content-box;
  background-image: url('/static/_v1/pd/icons/plus-18.png');
  background-position: center;
  background-position-x: 0;
  background-size: 32px;
  background-repeat: no-repeat;
  padding-left: 40px;
  padding-top: 7px;
  height: 25px;
  margin-bottom: 20px;
}

.footer__links-column {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  vertical-align: top;
}

.footer__links-column br {
  display: none;
}

.footer__links-column-title {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}

.footer__smi {
  margin-bottom: 20px;
}

.footer__age {
  margin-top: 20px;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.footer__certificates-wrap {
  padding-right: 20px;
}

.footer__certificates-img-wrap {
  height: 100px;
  width: 120px;
  background-color: white;
}

.footer__certificates-img {
  width: 100%;
  height: 100%;
  background-image: url('/static/_v1/pd/documents/organization/issn-probolezny-barcode.png');
  background-repeat: no-repeat;
  background-size: 111px;
  background-position: center;
}

.footer__img {
  width: 72px;
  height: 87px;
  background-image: url('/static/_v1/pd/logos/ru-reestr-programs.png');
  background-size: contain;
}

@media (max-width: 1199px) {
  .footer__block {
    width: 960px;
  }
}
