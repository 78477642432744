.b-choose-town-btn__switch {
  color: #555555 !important;
  font-size: 16px;
  border-bottom: 1px dashed;
  cursor: pointer;
  text-decoration: none;
  background-size: 100%;
  vertical-align: top;
  transition: 0.15s ease-in-out;
}

.b-choose-town-btn__switch:hover {
  border-color: transparent;
  color: #1A5DD0 !important;
}
