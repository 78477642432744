.p-404 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__column {
    flex: 1 0 50%;
    margin-top: 22px;
    margin-bottom: 90px;

    &:first-child {
      margin-right: 24px;
    }
  }

  &__review {
    margin-bottom: 8px;
  }

  &__btn {
    width: 168px;
  }
}

@media screen and (max-width: 1200px) {
  .p-404 {
    &__mascots {
      height: 480px;
    }
  }
}
