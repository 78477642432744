@import '~www/themes/doctors/common/variables';

// Цвета UI Kit
// Текст, основные
.ui-kit-bg-text {
  background-color: $ui-kit-text;
}

.ui-kit-bg-text-secondary {
  background-color: $ui-kit-text-secondary;
}

.ui-kit-bg-text-info {
  background-color: $ui-kit-text-info;
}


// Текст, дополнительные
.ui-kit-bg-text-success {
  background-color: $ui-kit-text-success;
}

.ui-kit-bg-text-error {
  background-color: $ui-kit-text-error;
}

.ui-kit-bg-text-warning {
  background-color: $ui-kit-text-warning;
}

.ui-kit-bg-disabled {
  background-color: $ui-kit-disabled;
}


// Иконки
.ui-kit-bg-icon-primary {
  background-color: $ui-kit-icon-primary;
}

.ui-kit-bg-icon-secondary {
  background-color: $ui-kit-icon-secondary;
}


// Цвета бренда
.ui-kit-bg-brand {
  background-color: $ui-kit-brand;
}

.ui-kit-bg-secondary {
  background-color: $ui-kit-secondary;
}

.ui-kit-bg-special {
  background-color: $ui-kit-special;
}


// Цвета состояний + вспомогательные
.ui-kit-bg-primary {
  background-color: $ui-kit-primary;
}

.ui-kit-bg-success {
  background-color: $ui-kit-success;
}

.ui-kit-bg-error {
  background-color: $ui-kit-error;
}

.ui-kit-bg-warning {
  background-color: $ui-kit-warning;
}


// Фоны + вспомогательные цвета
.ui-kit-bg-bg-primary {
  background-color: $ui-kit-bg-primary;
}

.ui-kit-bg-bg-secondary {
  background-color: $ui-kit-bg-secondary;
}

.ui-kit-bg-bg-special {
  background-color: $ui-kit-bg-special;
}

.ui-kit-bg-bg-success {
  background-color: $ui-kit-bg-success;
}

.ui-kit-bg-bg-error {
  background-color: $ui-kit-bg-error;
}

.ui-kit-bg-bg-warning {
  background-color: $ui-kit-bg-warning;
}

.ui-kit-bg-bg-club {
  background-color: $ui-kit-bg-club;
}


.ui-kit-bg-button-text-snackbars {
  background-color: $ui-kit-button-text-snackbars;
}

.ui-kit-bg-rating {
  background-color: $ui-kit-rating;
}


.ui-kit-bg-bg-gray-0 {
  background-color: $ui-kit-bg-gray-0;
}

.ui-kit-bg-bg-gray-40 {
  background-color: $ui-kit-bg-gray-40;
}

.ui-kit-bg-bg-gray-60 {
  background-color: $ui-kit-bg-gray-60;
}

.ui-kit-bg-bg-gray-80 {
  background-color: $ui-kit-bg-gray-80;
}
