.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-inline-block {
  display: inline-block !important;
}
