@import '~www/themes/doctors/common/variables';

.b-text-unit {
  text-decoration: none;
  line-height: $line-height-base;
  font-family: $font-family-regular;

  &_vertical_middle {
    display: inline-flex;
    align-items: center;
  }

  &_align-text_center {
    text-align: center;
  }

  &__text {
    transition: 0.15s ease-in-out;
  }

  &__icon {
    margin-right: 4px;

    &_margin-left {
      margin-left: 4px;
    }

    &_margin_lg {
      margin-right: 8px;
    }

    &_margin-right {
      &_md {
        margin-right: 16px;
      }
    }
  }

  &_color_brand {
    color: $ui-kit-primary;

    &:hover {
      color: $ui-kit-primary;
    }
  }

  &_color_medium {
    color: $color-dark-grey;

    &:hover {
      color: $color-dark-grey;
    }
  }

  &_color_dark {
    color: $color-neur-dark;

    &:hover {
      color: $color-neur-dark;
    }
  }

  &_color_light {
    color: $ui-kit-text-secondary;

    &:hover {
      color: $ui-kit-text-secondary;
    }
  }

  &_border_dashed {
    .b-text-unit__text {
      border-bottom: 1px dashed;
    }
  }

  &_border_solid {
    .b-text-unit__text {
      border-bottom: 1px solid;
    }
  }

  &_underline {
    text-decoration: underline;
  }

  &_weight_bold {
    font-weight: $weight-medium;
  }

  &_weight_medium {
    font-weight: $weight-medium;
  }

  &_weight_normal {
    font-weight: $weight-regular;
  }

  &_cursor_pointer {
    cursor: pointer;
  }

  &_hover {
    transition: 0.15s ease-in-out;

    &__text {
      transition: 0.15s ease-in-out;
    }
  }

  &_hover_unsolid {
    .b-text-unit__text {
      border-bottom: 1px solid;
    }

    &:hover {
      .b-text-unit__text {
        border-bottom-color: transparent;
      }
    }
  }

  /* stylelint-disable no-descending-specificity */
  &_hover_undashed {
    .b-text-unit__text {
      border-bottom: 1px dashed;
    }

    &:hover {
      .b-text-unit__text {
        border-bottom-color: transparent;
      }
    }
  }

  &_hover_solid {
    .b-text-unit__text {
      border-bottom-color: transparent;
    }

    &:hover {
      .b-text-unit__text {
        border-bottom: 1px solid;
      }
    }
  }
  /* stylelint-enable no-descending-specificity */

  &_hover_dark:hover {
    color: #0D5F92;

    .b-text-unit__text {
      border-bottom-color: transparent;
    }
  }

  &_mb {
    &_sm {
      margin-bottom: 16px;
    }
  }

  &_m {
    &_none {
      margin: 0;
    }
  }

  &_align_center {
    text-align: center;
  }
}

button.b-text-unit {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;

  &:hover {
    background-color: transparent;

    .b-text-unit__text {
      border-bottom-color: transparent;
    }
  }
}
