@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';


/**
Заставить webpack вставить `@charset 'UTF-8';` в начало выходного файла
 */
.--force-charset {
  content: '♫';
}

@font-face {
  font-family: icomoon;
  src: url('/static/_v1/pd/fonts/icomoon/iconfont.woff?2cd8e4') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='ui-icon-'],
[class*=' ui-icon-'] {
  @include icomoon-font;
}

/**
Модификаторы могут использоваться во Vuetify
Базовые стили Vuetify перебивают стили модификаторов(переопределение стилей Vuetify не поможет из-за вложенности селекторов)
*/

.ui-icon_fz_smaller {
  font-size: $ui-icon-fz-smaller !important;
}

.ui-icon_fz_medium {
  font-size: $ui-icon-fz-medium !important;
}

.ui-icon_fz_big {
  font-size: $ui-icon-fz-big !important;
}

.ui-icon_reset {
  width: initial;
  height: initial;
  display: initial;
  text-indent: initial;
  background: initial;
}

.ui-icon_type_40 {
  $icon-size: 40px;

  display: inline-block;
  width: $icon-size;
  height: $icon-size;
  line-height: $icon-size;
  color: $ui-kit-bg-gray-0;
  border-radius: 12px;
  text-align: center;
}

.ui-icon-logo-youtube {
  &::before {
    content: '\e9b5';
    color: rgb(255, 255, 255);
  }

  &::after {
    content: '\e9b6';
    margin-left: -1em;
    color: rgb(255, 74, 55);
  }
}

.ui-icon-user-add::before {
  content: '\e900';
}

.ui-icon-user-group::before {
  content: '\e901';
}

.ui-icon-user-only::before {
  content: '\e902';
}

.ui-icon-user-setting::before {
  content: '\e903';
}

.ui-icon-thumbs-up-empty::before {
  content: '\e904';
}

.ui-icon-thumbs-up-full::before {
  content: '\e905';
}

.ui-icon-share-arrow-up::before {
  content: '\e906';
}

.ui-icon-share-circle::before {
  content: '\e907';
}

.ui-icon-error-message::before {
  content: '\e908';
}

.ui-icon-mail-close::before {
  content: '\e909';
}

.ui-icon-player-volume-max::before {
  content: '\e90a';
}

.ui-icon-player-volume-middle::before {
  content: '\e90b';
}

.ui-icon-phone-call::before {
  content: '\e90c';
}

.ui-icon-phone-missed::before {
  content: '\e90d';
}

.ui-icon-octagon-alert::before {
  content: '\e90e';
}

.ui-icon-octagon-x::before {
  content: '\e90f';
}

.ui-icon-more-horizontal::before {
  content: '\e910';
}

.ui-icon-more-projects::before {
  content: '\e911';
}

.ui-icon-more-vertical::before {
  content: '\e912';
}

.ui-icon-ruble::before {
  content: '\e913';
}

.ui-icon-home-MedLock::before {
  content: '\e914';
}

.ui-icon-home-ProDoctorov::before {
  content: '\e915';
}

.ui-icon-gender-male::before {
  content: '\e916';
}

.ui-icon-gender-female::before {
  content: '\e917';
}

.ui-icon-file-file::before {
  content: '\e918';
}

.ui-icon-file-minus::before {
  content: '\e919';
}

.ui-icon-file-plus::before {
  content: '\e91a';
}

.ui-icon-eye-close::before {
  content: '\e91b';
}

.ui-icon-eye-open::before {
  content: '\e91c';
}

.ui-icon-close-not-a-circle::before {
  content: '\e91d';
}

.ui-icon-circle-account::before {
  content: '\e91e';
}

.ui-icon-circle-help::before {
  content: '\e91f';
}

.ui-icon-circle-info::before {
  content: '\e920';
}

.ui-icon-circle-minus::before {
  content: '\e921';
}

.ui-icon-circle-plus::before {
  content: '\e922';
}

.ui-icon-circle-slash::before {
  content: '\e923';
}

.ui-icon-checkbox-on::before {
  content: '\e924';
}

.ui-icon-checkbox-off::before {
  content: '\e925';
}

.ui-icon-redirect::before {
  content: '\e926';
}

.ui-icon-send-fill::before {
  content: '\e927';
}

.ui-icon-send::before {
  content: '\e928';
}

.ui-icon-bookmark-empty::before {
  content: '\e929';
}

.ui-icon-full::before {
  content: '\e92a';
}

.ui-icon-arrow-back::before {
  content: '\e92b';
}

.ui-icon-arrow-down::before {
  content: '\e92c';
}

.ui-icon-arrow-down-line::before {
  content: '\e92d';
}

.ui-icon-arrow-left::before {
  content: '\e92e';
}

.ui-icon-arrow-next::before {
  content: '\e92f';
}

.ui-icon-arrow-right::before {
  content: '\e930';
}

.ui-icon-arrow-up::before {
  content: '\e931';
}

.ui-icon-arrow-up-line::before {
  content: '\e932';
}

.ui-icon-check-doc::before {
  content: '\e933';
}

.ui-icon-message-circle-ignore::before {
  content: '\e934';
}

.ui-icon-circles-three-plus::before {
  content: '\e935';
}

.ui-icon-service::before {
  content: '\e936';
}

.ui-icon-settings::before {
  content: '\e937';
}

.ui-icon-shield::before {
  content: '\e938';
}

.ui-icon-shuffle::before {
  content: '\e939';
}

.ui-icon-sliders::before {
  content: '\e93a';
}

.ui-icon-tooth::before {
  content: '\e93b';
}

.ui-icon-trash::before {
  content: '\e93c';
}

.ui-icon-undo::before {
  content: '\e93d';
}

.ui-icon-upload-cloud::before {
  content: '\e93e';
}

.ui-icon-wallet::before {
  content: '\e93f';
}

.ui-icon-wheelchair::before {
  content: '\e940';
}

.ui-icon-save::before {
  content: '\e941';
}

.ui-icon-Scan::before {
  content: '\e942';
}

.ui-icon-search::before {
  content: '\e943';
}

.ui-icon-Reviews::before {
  content: '\e944';
}

.ui-icon-reset::before {
  content: '\e945';
}

.ui-icon-repeat::before {
  content: '\e946';
}

.ui-icon-redo::before {
  content: '\e947';
}

.ui-icon-jpg::before {
  content: '\e948';
}

.ui-icon-png::before {
  content: '\e949';
}

.ui-icon-excel::before {
  content: '\e94a';
}

.ui-icon-pdf::before {
  content: '\e9be';
}

.ui-icon-word::before {
  content: '\e9bf';
}

.ui-icon-file::before {
  content: '\e9e0';
}

.ui-icon-puzzle-piece::before {
  content: '\e94d';
}

.ui-icon-proverka::before {
  content: '\e94e';
}

.ui-icon-printer::before {
  content: '\e94f';
}

.ui-icon-post-web::before {
  content: '\e950';
}

.ui-icon-popular-rating::before {
  content: '\e951';
}

.ui-icon-plus::before {
  content: '\e952';
}

.ui-icon-place::before {
  content: '\e953';
}

.ui-icon-pie-chart::before {
  content: '\e954';
}

.ui-icon-percentage::before {
  content: '\e955';
}

.ui-icon-paperclip::before {
  content: '\e956';
}

.ui-icon-package::before {
  content: '\e957';
}

.ui-icon-open-plus::before {
  content: '\e958';
}

.ui-icon-open-plus-active::before {
  content: '\e959';
}

.ui-icon-navigation::before {
  content: '\e95a';
}

.ui-icon-minus::before {
  content: '\e95b';
}

.ui-icon-message-circle::before {
  content: '\e95c';
}

.ui-icon-menu::before {
  content: '\e95d';
}

.ui-icon-med-book::before {
  content: '\e95e';
}

.ui-icon-map-pin::before {
  content: '\e95f';
}

.ui-icon-map::before {
  content: '\e960';
}

.ui-icon-mail::before {
  content: '\e961';
}

.ui-icon-log-out::before {
  content: '\e962';
}

.ui-icon-logo-telegram::before {
  content: '\e963';
}

.ui-icon-logo-telegram-2::before {
  content: '\e9c3';
}

.ui-icon-list::before {
  content: '\e964';
}

.ui-icon-link-2::before {
  content: '\e965';
}

.ui-icon-layers::before {
  content: '\e966';
}

.ui-icon-laba::before {
  content: '\e967';
}

.ui-icon-Key::before {
  content: '\e968';
}

.ui-icon-hand-shaking::before {
  content: '\e969';
}

.ui-icon-image::before {
  content: '\e96a';
}

.ui-icon-image-regular::before {
  content: '\e96b';
}

.ui-icon-icon-card::before {
  content: '\e96c';
}

.ui-icon-icon-document-text::before {
  content: '\e96d';
}

.ui-icon-icon-kid-adult::before {
  content: '\e96e';
}

.ui-icon-icon-link::before {
  content: '\e96f';
}

.ui-icon-icon-parking::before {
  content: '\e970';
}

.ui-icon-icon-today::before {
  content: '\e971';
}

.ui-icon-icon-wifi::before {
  content: '\e972';
}

.ui-icon-headphones::before {
  content: '\e973';
}

.ui-icon-globe::before {
  content: '\e974';
}

.ui-icon-gift-turns-off::before {
  content: '\e975';
}

.ui-icon-flag::before {
  content: '\e976';
}

.ui-icon-filter::before {
  content: '\e977';
}

.ui-icon-edit::before {
  content: '\e978';
}

.ui-icon-download-arrow::before {
  content: '\e979';
}

.ui-icon-duplicate-user::before {
  content: '\e97a';
}

.ui-icon-download::before {
  content: '\e97b';
}

.ui-icon-doctor::before {
  content: '\e97c';
}

.ui-icon-denture::before {
  content: '\e97d';
}

.ui-icon-create::before {
  content: '\e97e';
}

.ui-icon-copy::before {
  content: '\e97f';
}

.ui-icon-coin::before {
  content: '\e980';
}

.ui-icon-clock::before {
  content: '\e981';
}

.ui-icon-clinic::before {
  content: '\e982';
}

.ui-icon-check-on::before {
  content: '\e983';
}

.ui-icon-check-circle::before {
  content: '\e984';
}

.ui-icon-balance::before {
  content: '\e985';
}

.ui-icon-site::before {
  content: '\e986';
}

.ui-icon-cart::before {
  content: '\e987';
}

.ui-icon-card::before {
  content: '\e988';
}

.ui-icon-car-clinic::before {
  content: '\e989';
}

.ui-icon-camera::before {
  content: '\e98a';
}

.ui-icon-cabinets::before {
  content: '\e98b';
}

.ui-icon-bell::before {
  content: '\e98d';
}

.ui-icon-book::before {
  content: '\e98e';
}

.ui-icon-award::before {
  content: '\e990';
}

.ui-icon-at-sign::before {
  content: '\e991';
}

.ui-icon-archive::before {
  content: '\e992';
}

.ui-icon-alarm::before {
  content: '\e993';
}

.ui-icon-flashlight::before {
  content: '\e994';
}

.ui-icon-flashlight-no::before {
  content: '\e995';
}

.ui-icon-radio-off::before {
  content: '\e996';
}

.ui-icon-radio-on::before {
  content: '\e997';
}

.ui-icon-calendar-check::before {
  content: '\e998';
}

.ui-icon-calendar-close::before {
  content: '\e999';
}

.ui-icon-calendar-date-and-clock::before {
  content: '\e99a';
}

.ui-icon-calendar-date-change::before {
  content: '\e9c0';
}

.ui-icon-calendar-date::before {
  content: '\e99b';
}

.ui-icon-calendar-none::before {
  content: '\e99c';
}

.ui-icon-pen::before {
  content: '\e99d';
}

.ui-icon-gift::before {
  content: '\e99e';
}

.ui-icon-player-back::before {
  content: '\e99f';
}

.ui-icon-player-next::before {
  content: '\e9a0';
}

.ui-icon-player-play::before {
  content: '\e9a1';
}

.ui-icon-player-stop::before {
  content: '\e9a2';
}

.ui-icon-check::before {
  content: '\e9a3';
}

.ui-icon-delete::before {
  content: '\e9a4';
}

.ui-icon-google::before {
  content: '\e9a5';
}

.ui-icon-chat::before {
  content: '\e9a6';
}

.ui-icon-clinic-pd::before {
  content: '\e9a7';
}

.ui-icon-degree-hat::before {
  content: '\e9a8';
}

.ui-icon-doc::before {
  content: '\e9a9';
}

.ui-icon-three-people::before {
  content: '\e9aa';
}

.ui-icon-heart::before {
  content: '\e9ab';
}

.ui-icon-gender-all::before {
  content: '\e9ac';
}

.ui-icon-history::before {
  content: '\e9ad';
}

.ui-icon-close-circle::before {
  content: '\e9ae';
}

.ui-icon-doc-download::before {
  content: '\e9af';
}

.ui-icon-circle-warning::before {
  content: '\e9b0';
}

.ui-icon-bookmark-full::before {
  content: '\e9b1';
}

.ui-icon-doc-upload::before {
  content: '\e9b2';
}

.ui-icon-circle-close::before {
  content: '\e9b3';
}

.ui-icon-heart-active::before {
  content: '\e9b4';
}

.ui-icon-uniE9B5::before {
  content: '\e9b5';
}

.ui-icon-uniE9B6::before {
  content: '\e9b6';
}

.ui-icon-clockwise::before {
  content: '\e9b7';
}

.ui-icon-youtube::before {
  content: '\e9b8';
}

.ui-icon-video-camera::before {
  content: '\e9b9';
}

.ui-icon-circle-dashed::before {
  content: '\e9ba';
}

.ui-icon-time-circle-half::before {
  content: '\e9bb';
}

.ui-icon-instagram-logo::before {
  content: '\e9bc';
}

.ui-icon-lock-key-success::before {
  content: '\e9bd';
}

.ui-icon-one-c::before {
  content: '\e9c1';
}

.ui-icon-indeterminate::before {
  content: '\e9c2';
}

.ui-icon-rating-star-filled::before {
  content: '\e9dc';
}

.ui-icon-rating-star-half::before {
  content: '\e9dd';
}

.ui-icon-rating-star-outline::before {
  content: '\e9de';
}

.ui-icon-support::before {
  content: '\e9df';
}

.ui-icon-data-graph::before {
  content: '\e9e1';
}

.ui-icon-date-change::before {
  content: '\e9e2';
}

.ui-icon-shield-check::before {
  content: '\e9e3';
}

.ui-icon-lock-key::before {
  content: '\e9e4';
}

.ui-icon-receipt::before {
  content: '\e9e5';
}

.ui-icon-magnifying-glass-plus::before {
  content: '\e9e6';
}

.ui-icon-magnifying-glass-minus::before {
  content: '\e9e9';
}

.ui-icon-reset-2::before {
  content: '\e9e7';
}

.ui-icon-vault::before {
  content: '\e9e8';
}

.ui-icon-message-whatsapp::before {
  content: '\e9ea';
}

.ui-icon-car-prodoctorov::before {
  content: '\e9eb';
}

.ui-icon-map-full::before {
  content: '\e9ec';
}

.ui-icon-plugs::before {
  content: '\e9ed';
}

.ui-icon-monitor::before {
  content: '\e9ee';
}

.ui-icon-idea::before {
  content: '\e9ef';
}

.ui-icon-lifebuoy::before {
  content: '\e9f0';
}

.ui-icon-monitor-play::before {
  content: '\e9f1';
}

.ui-icon-phone-chat::before {
  content: '\e9f2';
}

.ui-icon-phone-in::before {
  content: '\e9f3';
}

.ui-icon-phone-end::before {
  content: '\e9f4';
}

.ui-icon-phone-none::before {
  content: '\e9f5';
}

.ui-icon-phone-out::before {
  content: '\e9f6';
}

.ui-icon-file-arrow::before {
  content: '\e9f7';
}

.ui-icon-file-text::before {
  content: '\e9f8';
}

.ui-icon-docs-compare::before {
  content: '\e9f9';
}

.ui-icon-picture-off::before {
  content: '\e9fa';
}

.ui-icon-picture-on::before {
  content: '\e9fb';
}

.ui-icon-map-pin-filled::before {
  content: '\e9fc';
}

.ui-icon-arrow-link::before {
  content: '\e9fd';
}

.ui-icon-education::before {
  content: '\e9fe';
}

.ui-icon-note::before {
  content: '\e9ff';
}

.ui-icon-gerb::before {
  content: '\ea00';
}

.ui-icon-geo-detect::before {
  content: '\ea01';
}

.ui-icon-auto::before {
  content: '\ea02';
}

.ui-icon-bus::before {
  content: '\ea03';
}

.ui-icon-metro::before {
  content: '\ea04';
}

.ui-icon-pedestrian::before {
  content: '\ea05';
}

.ui-icon-tag::before {
  content: '\ea06';
}

.ui-icon-tram::before {
  content: '\ea07';
}

.ui-icon-eraser::before {
  content: '\ea08';
}

.ui-icon-crop::before {
  content: '\ea09';
}

.ui-icon-message-fill::before {
  content: '\ea0a';
}

.ui-icon-mf-fill::before {
  content: '\ea0b';
}

.ui-icon-mt-fill::before {
  content: '\ea0d';
}

.ui-icon-wa-fill::before {
  content: '\ea0f';
}

.ui-icon-calendar-date-7::before {
  content: '\ea10';
}

.ui-icon-magnet::before {
  content: '\ea11';
}

.ui-icon-pic-doc::before {
  content: '\ea12';
}

.ui-icon-bar-chart::before {
  content: '\ea0c';
}

.ui-icon-list-checks::before {
  content: '\ea0e';
}

.ui-icon-enter::before {
  content: '\ea13';
}

.ui-icon-duty::before {
  content: '\ea14';
}

.ui-icon-copyright::before {
  content: '\ea15';
}

.ui-icon-vk::before {
  content: '\ea16';
}

.ui-icon-kassa-401::before {
  content: '\ea17';
}

.ui-icon-additional-info-40::before {
  content: '\ea18';
}

.ui-icon-analysis-40::before {
  content: '\ea19';
}

.ui-icon-archive-40::before {
  content: '\ea1a';
}

.ui-icon-arrow-up-40::before {
  content: '\ea1b';
}

.ui-icon-bill-40::before {
  content: '\ea1c';
}

.ui-icon-bonus-40::before {
  content: '\ea1d';
}

.ui-icon-box-1-40::before {
  content: '\ea1e';
}

.ui-icon-box-2-40::before {
  content: '\ea1f';
}

.ui-icon-box-40::before {
  content: '\ea20';
}

.ui-icon-call-40::before {
  content: '\ea21';
}

.ui-icon-card-40::before {
  content: '\ea22';
}

.ui-icon-chart-40::before {
  content: '\ea23';
}

.ui-icon-club-card-40::before {
  content: '\ea24';
}

.ui-icon-recommendation-40::before {
  content: '\ea25';
}

.ui-icon-dates-40::before {
  content: '\ea26';
}

.ui-icon-delete-40::before {
  content: '\ea27';
}

.ui-icon-dental-40::before {
  content: '\ea28';
}

.ui-icon-doc-40::before {
  content: '\ea29';
}

.ui-icon-docs-40::before {
  content: '\ea2a';
}

.ui-icon-documents-1-40::before {
  content: '\ea2b';
}

.ui-icon-documents-2-40::before {
  content: '\ea2c';
}

.ui-icon-exit-40::before {
  content: '\ea2d';
}

.ui-icon-family-40::before {
  content: '\ea2e';
}

.ui-icon-flag-40::before {
  content: '\ea2f';
}

.ui-icon-hi-40::before {
  content: '\ea30';
}

.ui-icon-history-40::before {
  content: '\ea31';
}

.ui-icon-hospital-1-40::before {
  content: '\ea32';
}

.ui-icon-idcard-40::before {
  content: '\ea33';
}

.ui-icon-mail-40::before {
  content: '\ea34';
}

.ui-icon-computer-40::before {
  content: '\ea35';
}

.ui-icon-mail-opened-40::before {
  content: '\ea36';
}

.ui-icon-pie-40::before {
  content: '\ea37';
}

.ui-icon-settings-1-40::before {
  content: '\ea38';
}

.ui-icon-settings-2-40::before {
  content: '\ea39';
}

.ui-icon-interview-40::before {
  content: '\ea3a';
}

.ui-icon-kassa-40::before {
  content: '\ea3b';
}

.ui-icon-like-40::before {
  content: '\ea3c';
}

.ui-icon-lock-40::before {
  content: '\ea3d';
}

.ui-icon-medflex-40::before {
  content: '\ea3e';
}

.ui-icon-megaphone-40::before {
  content: '\ea3f';
}

.ui-icon-mobile-phone-40::before {
  content: '\ea40';
}

.ui-icon-mri-40::before {
  content: '\ea41';
}

.ui-icon-notification-40::before {
  content: '\ea42';
}

.ui-icon-pay-online-40::before {
  content: '\ea43';
}

.ui-icon-pencil-40::before {
  content: '\ea44';
}

.ui-icon-phone-2-40::before {
  content: '\ea45';
}

.ui-icon-phone-40::before {
  content: '\ea46';
}

.ui-icon-photo-camera-40::before {
  content: '\ea47';
}

.ui-icon-photos-40::before {
  content: '\ea48';
}

.ui-icon-pills-40::before {
  content: '\ea49';
}

.ui-icon-place-40::before {
  content: '\ea4a';
}

.ui-icon-planet-40::before {
  content: '\ea4b';
}

.ui-icon-puzzle-40::before {
  content: '\ea4c';
}

.ui-icon-question-1-40::before {
  content: '\ea4d';
}

.ui-icon-questions-2-40::before {
  content: '\ea4e';
}

.ui-icon-rating-40::before {
  content: '\ea4f';
}

.ui-icon-refresh-40::before {
  content: '\ea50';
}

.ui-icon-reviews-40::before {
  content: '\ea51';
}

.ui-icon-robot-40::before {
  content: '\ea52';
}

.ui-icon-salary-40::before {
  content: '\ea53';
}

.ui-icon-shield-40::before {
  content: '\ea54';
}

.ui-icon-specially-40::before {
  content: '\ea55';
}

.ui-icon-stetoscope-40::before {
  content: '\ea56';
}

.ui-icon-support-40::before {
  content: '\ea57';
}

.ui-icon-syringe-40::before {
  content: '\ea58';
}

.ui-icon-telegram-40::before {
  content: '\ea59';
}

.ui-icon-thermometr-40::before {
  content: '\ea5a';
}

.ui-icon-update-pricing-40::before {
  content: '\ea5b';
}

.ui-icon-user-40::before {
  content: '\ea5c';
}

.ui-icon-viber-40::before {
  content: '\ea5d';
}

.ui-icon-video-40::before {
  content: '\ea5e';
}

.ui-icon-vk-40::before {
  content: '\ea5f';
}

.ui-icon-whatsapp-40::before {
  content: '\ea60';
}

.ui-icon-widget-40::before {
  content: '\ea61';
}

.ui-icon-atol-40::before {
  content: '\ea6a';
}

.ui-icon-ads-40::before {
  content: '\ea6c';
}

.ui-icon-blacklist-40::before {
  content: '\ea6d';
}

.ui-icon-calc-40::before {
  content: '\ea6e';
}

.ui-icon-calendar-link-40::before {
  content: '\ea6f';
}

.ui-icon-education-40::before {
  content: '\ea70';
}

.ui-icon-link-all-40::before {
  content: '\ea71';
}

.ui-icon-widget-401::before {
  content: '\ea72';
}

.ui-icon-pd-40::before {
  content: '\ea73';
}

.ui-icon-mt_bonus::before {
  content: '\ea62';
}

.ui-icon-circle_up::before {
  content: '\ea63';
}

.ui-icon-expand::before {
  content: '\ea64';
}

.ui-icon-roll-up::before {
  content: '\ea65';
}

.ui-icon-magic::before {
  content: '\ea66';
}

.ui-icon-megaphone::before {
  content: '\ea67';
}

.ui-icon-phone-check::before {
  content: '\ea68';
}

.ui-icon-qr-code::before {
  content: '\ea69';
}

.ui-icon-circle-percentage::before {
  content: '\ea6b';
}

.ui-icon-ml-first::before {
  content: '\ea74';
}

.ui-icon-ml-comment::before {
  content: '\ea75';
}

.ui-icon-ml-target::before {
  content: '\ea76';
}

.ui-icon-data-get::before {
  content: '\ea77';
}

.ui-icon-data-transmit::before {
  content: '\ea78';
}

.ui-icon-coupon-40::before {
  content: '\ea79';
}

.ui-icon-discount-40::before {
  content: '\ea7a';
}

.ui-icon-autopay::before {
  content: '\ea7b';
}

.ui-icon-all-folders::before {
  content: '\ea7c';
}

.ui-icon-close-open-40::before {
  content: '\ea7d';
}

.ui-icon-medrocket-logo-40::before {
  content: '\ea7e';
}

.ui-icon-women-calendar-40::before {
  content: '\ea82';
}

.ui-icon-disease::before {
  content: '\ea7f';
}

.ui-icon-metro-alt::before {
  content: '\ea80';
}

.ui-icon-identification-card::before {
  content: '\ea81';
}

.ui-icon-QR-code-40::before {
  content: '\ea83';
}

.ui-icon-cleanreck-40::before {
  content: '\ea84';
}

.ui-icon-circle-arrow-right::before {
  content: '\ea85';
}

.ui-icon-censor::before {
  content: '\ea86';
}

.ui-icon-cash::before {
  content: '\ea87';
}

.ui-icon-duty-2::before {
  content: '\ea88';
}

.ui-icon-viber::before {
  content: '\ea89';
}

.ui-icon-no-send::before {
  content: '\ea8a';
}

.ui-icon-down-up::before {
  content: '\ea8b';
}

.ui-icon-calendar-mt-fill::before {
  content: '\ea8c';
}

.ui-icon-calendar-mt::before {
  content: '\ea8d';
}

.ui-icon-profile-mt::before {
  content: '\ea8e';
}

.ui-icon-profile-mt-fill::before {
  content: '\ea8f';
}

.ui-icon-trophy-40::before {
  content: '\ea90';
}

.ui-icon-shield-checkmark-40::before {
  content: '\ea91';
}

.ui-icon-create-with-ai::before {
  content: '\ea92';
}

.ui-icon-club_card::before {
  content: '\ea93';
}

.ui-icon-attention-40::before {
  content: '\ea94';
}

.ui-icon-done-40::before {
  content: '\ea95';
}

.ui-icon-promocode::before {
  content: '\ea96';
}

.ui-icon-rocket::before {
  content: '\ea97';
}

.ui-icon-check-circle-filled::before {
  content: '\ea98';
}

.ui-icon-piggy-bank-40::before {
  content: '\ea99';
}

.ui-icon-dates-not-40::before {
  content: '\ea9a';
}

.ui-icon-text-align::before {
  content: '\ea9b';
}

.ui-icon-frown-filled::before {
  content: '\ea9c';
}

.ui-icon-smile-filled::before {
  content: '\ea9d';
}

.ui-icon-scales::before {
  content: '\ea9e';
}

.ui-icon-heart-broken::before {
  content: '\ea9f';
}

.ui-icon-link-40::before {
  content: '\eaa0';
}

.ui-icon-route::before {
  content: '\eaa1';
}

.ui-icon-question::before {
  content: '\eaa2';
}

.ui-icon-test-tube::before {
  content: '\eaa3';
}
