@import '~www/themes/doctors/common/variables';

.b-btn {
  font-family: $font-family-regular;
  font-size: $font-size-body-1;
  line-height: 1em;
  display: inline-block;
  padding: 1em 2em;
  font-weight: $weight-medium;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  transition: all 0.2s ease-out 0s;
  outline: none;
  background-image: none;
  border: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  box-sizing: border-box;
  height: auto;
  border-radius: 5px;

  &__text {
    margin: 0 8px;
  }

  &_color {
    &_blue {
      background: $ui-kit-primary;
      color: $ui-kit-bg-gray-0;

      &:hover {
        background: darken($ui-kit-primary, 10);
        color: $ui-kit-bg-gray-0;
      }
    }

    &_primary-border {
      border: 2px solid $ui-kit-primary;
      color: $ui-kit-primary;

      &:hover {
        border-color: darken($ui-kit-primary, 10);
        color: darken($ui-kit-primary, 10);
      }
    }

    &_red {
      background: $color-original-red;
      color: $ui-kit-bg-gray-0;

      &:hover {
        background: darken($color-original-red, 10);
      }
    }

    &_light-blue {
      background: $ui-kit-bg-primary;
      border: none;
      color: $ui-kit-primary;

      &:hover {
        background: #CEE3EF;
        color: darken($ui-kit-primary, 10);
      }
    }

    &_white {
      border: 1px solid $color-deep-soft-blue;
      background: $ui-kit-bg-gray-0;
      color: $color-neur-dark;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);

      &:hover {
        background-color: $ui-kit-bg-gray-0;
        color: $color-neur-dark;
        box-shadow: none;
      }
    }

    &_shadow-border {
      border: 1px solid $color-deep-soft-blue;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);

      &:hover {
        box-shadow: none;
      }
    }
  }

  &_no-border {
    border: none;
    box-shadow: none;
  }

  &_color_blue.b-btn_disabled {
    background: #7AB8DE;
    opacity: 0.9;
    cursor: not-allowed;
  }

  &_disabled {
    background: #7AB8DE;
    opacity: 0.9;
    cursor: not-allowed;
  }
}

.b-btn_text_blue {
  color: $ui-kit-primary;
}
