@import '~www/themes/doctors/common/variables';

.p-page {
  font-family: $font-family-regular;

  &__intro-description-link {
    color: $ui-kit-primary;
    border-bottom: 1px dashed $ui-kit-primary;
    text-decoration: none;

    &:hover {
      transition: 0.3s;
      border-bottom: 1px dashed transparent;
      color: #0D5F92;
    }
  }

  &__title {
    margin: 0 0 16px;
    font-size: 24px;
    line-height: 135%;
    color: $ui-kit-text;
    font-weight: $weight-regular;
    display: inline-block;
    font-style: normal;

    &_text_bold {
      font-weight: $weight-medium;
    }

    &_text_regular {
      font-weight: $weight-regular;
    }

    &_color_grey {
      color: $ui-kit-text-secondary;
    }

    &[data-counter]::after {
      content: attr(data-counter);
      font-size: $font-size-xl;
      line-height: 1.3em;
      margin-left: 8px;
      vertical-align: top;
    }
  }

  &__title-count {
    font-size: $font-size-xl;
    font-weight: $weight-regular;
    line-height: 1.3em;
    color: $color-neur-dark;
    margin-left: 8px;
    vertical-align: top;
  }

  &__title-page-num {
    color: $ui-kit-text-secondary;
    font-size: $font-size-body-1;
    font-weight: $weight-regular;
    padding-left: 16px;
  }

  &__intro-description {
    font-size: $font-size-body-1;
    line-height: 1.3em;
    font-weight: $weight-regular;
    color: $ui-kit-text-secondary;
  }
}
