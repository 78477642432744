@import '~www/themes/doctors/common/variables';

.b-link {
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: transparent;

    // не использую 'transparent' для поддержки safari
    border-bottom-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    color: $ui-kit-primary;
    cursor: pointer;
  }

  /*
    * Использовать цвета для ссылок из блока 'ui-text' не получится, т.к. их переопределяют стили из '8.css',
    * поэтому был создан отдельно модификатор '&_color' конкретно для ссылок, а ещё у 'ui-text' нет стилей при наведении.
    * Также были добавлены декларации '!important' цветам для того, чтобы переопределить '8.css' и 'ress.css' (сброс стилей от Vuetify)
    */
  &_color {
    &_grey {
      color: $ui-kit-text-secondary !important;
      border-color: currentColor !important;

      &:hover {
        color: $ui-kit-text-secondary !important;
      }
    }

    &_neur-blue {
      color: $ui-kit-primary !important;

      &:hover {
        color: $ui-kit-primary !important;
      }
    }

    &_primary-blue {
      color: $ui-kit-primary !important;

      &:hover {
        color: $ui-kit-primary !important;
      }
    }

    &_original-red {
      color: $color-original-red !important;

      &:hover {
        color: $color-original-red !important;
      }
    }

    &_black {
      color: $ui-kit-text !important;

      &:hover {
        color: $ui-kit-text !important;
      }
    }

    &_green {
      color: $ui-kit-success !important;

      &:hover {
        color: $ui-kit-success !important;
      }
    }

    &_coral {
      color: $ui-kit-error !important;

      &:hover {
        color: $ui-kit-error !important;
      }
    }

    &_text-error {
      color: $ui-kit-text-error !important;

      &:hover {
        color: $ui-kit-text-error !important;
      }
    }
  }

  &_prg {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    background: none;
    height: auto;
    border: none;
    padding: 0;
    text-align: left;
    border-radius: initial;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &_flex {
    display: flex;
    flex-shrink: 0;
  }

  &_align-center {
    align-items: center;
  }

  // @deprecated, используем - `&_underline`
  &_solid {
    border-bottom: 1px solid currentColor;
  }

  // @deprecated, используем - `&_underline`
  &_dashed {
    border-bottom: 1px dashed currentColor;
  }

  &_underline,
  &_underline_hover:hover {
    text-decoration: underline;
  }

  &_transparent-border {
    border-bottom-color: transparent;
  }
}
