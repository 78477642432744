@import '~www/themes/doctors/common/variables';

.b-change-flavour {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  border-bottom: 1px solid #444444 !important;
  cursor: pointer;
  border-radius: 0 !important;
  margin: 0 auto;
  display: block;
  white-space: nowrap;
  box-shadow: none !important;
  width: 130px !important;
  outline: none;
}

.b-change-flavour:hover {
  color: red !important;
  border-bottom-color: red !important;
}
