@import '~www/themes/doctors/common/variables';

// См. https://v2.vuetifyjs.com/en/styles/border-radius/
// Соответствует `rounded`-классам vuetify текущего проекта (тут перечислены не все классы)
$rounded: (
  '0': 0,
  'md': $border-radius-sm,
  'lg': $border-radius-md,
  'xl': $border-radius-xl,
  'pill': 9999px,
  'round': $border-radius-round,
  'circle': $border-radius-circle
);
$directions: (
  't': ('top-left', 'top-right'),
  'b': ('bottom-left', 'bottom-right'),
  'l': ('top-left', 'bottom-left'),
  'r': ('top-right', 'bottom-right'),
  'tl': ('top-left'),
  'tr': ('top-right'),
  'bl': ('bottom-left'),
  'br': ('bottom-right'),
  '': ('top-left', 'top-right', 'bottom-left', 'bottom-right'),
);

@each $rounded-key, $rounded-value in $rounded {
  @each $direction-key, $direction-value in $directions {
    $class: 'rounded' + if($direction-key == '', '', '-' + $direction-key);
    $class: $class + if($rounded-key == 'md', '', '-' + $rounded-key);

    .#{$class} {
      @each $prop in $direction-value {
        border-#{$prop}-radius: $rounded-value !important;
      }
    }
  }
}
