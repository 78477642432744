@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

$width: 960;
$half-width: 555;
$half-media-width: 570;
$media-width: 1120;

.b-container {
  width: $width + px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    width: $media-width + px;
  }

  &_margin_bottom {
    margin-bottom: 32px;
  }

  &_fonts {
    font-family: $font-family-regular;
  }

  &_bg_white {
    background: $ui-kit-bg-gray-0;
    padding: 24px;
    position: relative;
    box-shadow: 0 15px 20px rgba(151, 158, 167, 0.1);
    border-radius: 5px;
  }

  &_half_width {
    width: $half-width + px;
    margin: 0;

    @include media-breakpoint-up(lg) {
      width: $half-media-width + px;
    }
  }
}

.b-slider-container {
  width: ($width + 40) + px;
  margin: 0 auto;
  position: relative;

  @include media-breakpoint-up(lg) {
    width: ($media-width + 40) + px;
  }

  &__preloader {
    position: absolute;
    height: 320px;
    width: 100%;
    background: $ui-kit-bg-gray-0 url('/static/_v1/pd/gif/town-slider-preloader.gif') no-repeat center /15%;
  }
}
