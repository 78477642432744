@import '~www/themes/doctors/common/variables';

.b-choose-login-type {
  &__login-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:first-child {
      border-bottom: 1px solid $color-light-grey;
    }
  }

  &__content {
    width: 259px;
  }

  &__layer {
    @include elevation-8;
  }
}
