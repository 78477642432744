@import '~www/themes/doctors/common/variables';

.ui-text {
  font-family: $font-family-regular;
  font-style: normal;

  // fix для МедОтвета
  text-shadow: none;

  &_h1 {
    font-size: $font-size-h1;
    font-weight: $weight-regular;
    line-height: 120%;
    letter-spacing: -0.015em;
  }

  &_h2 {
    font-size: $font-size-h2;
    font-weight: $weight-regular;
    line-height: 120%;
    letter-spacing: -0.005em;
  }

  &_h3 {
    font-size: $font-size-h3;
    font-weight: $weight-regular;
    line-height: 130%;
    letter-spacing: 0;
  }

  &_h4 {
    font-size: $font-size-h4;
    font-weight: $weight-regular;
    line-height: 130%;
    letter-spacing: 0;
  }

  &_h5 {
    font-weight: $weight-regular;
    font-size: $font-size-h5;
    line-height: 135%;
    letter-spacing: 0;
  }

  &_h6 {
    font-weight: $weight-medium;
    font-size: $font-size-h6;
    line-height: 130%;
    letter-spacing: 0.15px;
  }

  &_subtitle-1 {
    font-weight: $weight-medium;
    font-size: $font-size-subtitle-1;
    line-height: 135%;
    letter-spacing: 0;
  }

  &_subtitle-2 {
    font-weight: $weight-medium;
    font-size: $font-size-subtitle-2;
    line-height: 140%;
    letter-spacing: 0;
  }

  &_subtitle-3 {
    font-weight: $weight-medium;
    font-size: $font-size-subtitle-3;
    line-height: 15px;
    letter-spacing: 0;
  }

  &_body-1 {
    font-weight: $weight-regular;
    font-size: $font-size-body-1;
    line-height: 125%;
    letter-spacing: 0;
  }

  &_body-2 {
    font-weight: $weight-regular;
    font-size: $font-size-body-2;
    line-height: 140%;
    letter-spacing: 0;
  }

  &_button,
  &_button_small {
    font-weight: $weight-medium;
    font-size: $font-size-button;
    line-height: 135%;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &_button_small {
    font-size: $font-size-button-small;
    line-height: 140%;
  }

  &_caption {
    font-weight: $weight-regular;
    font-size: $font-size-caption;
    line-height: 130%;
    letter-spacing: 0.004em;
  }

  &_overline {
    font-weight: $weight-regular;
    font-size: $font-size-overline;
    line-height: 16px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
  }

  &_line-through {
    text-decoration-line: line-through;
  }

  &_underline {
    text-decoration-line: underline;
  }

  &_without-line {
    text-decoration-line: none;
  }

  &_nowrap {
    white-space: nowrap;
  }

  &_break {
    word-break: break-word;
  }

  &_truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &_dot-after {
    text-decoration-line: none;

    &:not(:last-child)::after {
      content: '\00B7';
    }
  }

  // COLORS
  &_color {
    &_neur-dark {
      color: $color-neur-dark;
    }

    &_dark-blue {
      color: $new-color-dark-blue;
    }

    &_soft-blue {
      color: $color-deep-soft-blue;
    }

    &_neur-green {
      color: $color-neur-green;
    }

    &_original-red {
      color: $color-original-red;
    }

    &_gold {
      color: $new-color-gold;
    }

    &_text-secondary {
      color: $ui-kit-text-secondary;
    }

    &_primary {
      color: $ui-kit-primary;
    }
  }
}
