@import '~www/themes/doctors/common/variables';

.b-menu-line {
  font-family: $font-family-regular;
  margin-bottom: 24px;

  &__container {
    margin-bottom: 24px;
  }

  &__menu-list {
    padding: 0;
    margin-bottom: 24px;
  }

  &__menu-list-item {
    display: inline-block;
    margin-left: 16px;
  }

  &__menu-list-item:first-child {
    margin-left: 0;
  }

  &__menu-list-item-link {
    padding: 9px 18px;
    display: block;
    border: 1px solid $color-deep-soft-blue;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 40px;
    font-size: $font-size-body-1;
    color: $ui-kit-primary;
    transition: 0.2s ease-in-out;
    background-color: $ui-kit-bg-gray-0;

    &:hover {
      color: $ui-kit-secondary;
    }
  }

  &__menu-list-item-rc {
    font-size: $font-size-body-2;
    color: $color-dark-grey;
    font-weight: $weight-medium;
  }
}
